// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//= require rails-ujs
//= require activestorage

import '../lazy_load.js';
import '../reload.js';

////////////////////
// Flash message
////////////////////
$(function(){
  setTimeout("$('.alert').fadeOut('slow')", 3000)
})


// desktop

////////////////////
// Showing personal menu
////////////////////
$(function () {
  $('.headerPersonal_mypageIcon').on("click", function() {
    $('.headerPersonal__menuToggle-menu').toggle();
  });
});

// mobile

////////////////////
// Scroll to top of page
////////////////////
$(function() {
  $('.jsScrollTopButton').on('click',function() {
    $('body, html').animate({scrollTop: 0}, 300);
    return false;
  });
});

////////////////////
// Slide toggle when clicking mypage
////////////////////
$(function () {
  $('#jsUserNavigationOpen').on('click', function () {
    $('#jsUserDrawer').slideToggle();
  });

  $('.jsBrowserDrawerOperationClose').on('click', function () {
    $('#jsUserDrawer').slideToggle();
  });
});